<template>
  <div class="bandwidth-rating">
    <div class="bandwidth-rating-icon">
      <svg
        viewBox="0 0 89 69"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="1479410" fill-rule="nonzero">
            <path
              d="M32.2,69 C28.1,69 24.8,65.7 24.8,61.6 L24.8,41.9 C24.8,37.8 28.1,34.5 32.2,34.5 C36.3,34.5 39.6,37.8 39.6,41.9 L39.6,61.6 C39.6,65.7 36.3,69 32.2,69 Z"
              :class="bandwidthRatioComputed >= 25 ? 'active' : 'not-active'"
              :style="{ fill: bandwidthLostColorComputed }"
            ></path>
            <path
              d="M7.5,69 C3.4,69 0.1,65.7 0.1,61.6 C0.1,57.5 3.4,54.2 7.5,54.2 C11.6,54.2 14.9,57.5 14.9,61.6 C14.9,65.7 11.6,69 7.5,69 Z"
              :class="bandwidthRatioComputed >= 50 ? 'active' : 'not-active'"
              :style="{ fill: bandwidthLostColorComputed }"
            ></path>
            <path
              d="M56.8,69 C52.7,69 49.4,65.7 49.4,61.6 L49.4,27.1 C49.4,23 52.7,19.7 56.8,19.7 C60.9,19.7 64.2,23 64.2,27.1 L64.2,61.6 C64.2,65.7 60.9,69 56.8,69 Z"
              :class="bandwidthRatioComputed >= 75 ? 'active' : 'not-active'"
              :style="{ fill: bandwidthLostColorComputed }"
            ></path>
            <path
              d="M81.5,69 C77.4,69 74.1,65.7 74.1,61.6 L74.1,7.4 C74.1,3.3 77.4,0 81.5,0 C85.6,0 88.9,3.3 88.9,7.4 L88.9,61.6 C88.8,65.7 85.5,69 81.5,69 Z"
              :class="bandwidthRatioComputed >= 100 ? 'active' : 'not-active'"
              :style="{ fill: bandwidthLostColorComputed }"
            ></path>
          </g>
        </g>
      </svg>
    </div>
    <div class="bandwidth-rating-text">{{ getWebengineBandwidth }} Mbps</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "BandwidthIndicator",
  props: {},
  data: () => ({
    bandwidthRatio: 0,
    lost: 0,
    activeColor: "#388E3C",
  }),
  methods: {
    calculatePercent(small, big) {
      if (small === 0 || big - small === 0) return 0
      return ((big - small) / small) * 100
    },
  },
  computed: {
    ...mapGetters({
      getWebengineBandwidth: "getWebengineBandwidth",
      getWebengineCurrentReceived: "getWebengineCurrentReceived",
      getWebengineCurrentLost: "getWebengineCurrentLost",
      getWebengineCurrentSended: "getWebengineCurrentSended",
    }),
    bandwidthRatioComputed() {
      let bandwidth =
        this.getWebengineCurrentReceived + this.getWebengineCurrentSended
      let percent = this.calculatePercent(bandwidth, this.getWebengineBandwidth)
      return percent
    },
    bandwidthLostColorComputed() {
      if (this.getWebengineCurrentLost > 0) {
        return "#ffd055"
      } else {
        return "#388E3C"
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.bandwidth-rating
  flex: 0 0 auto
  margin: 0 10px
  display: flex
  align-items: center
  flex-direction: column
  .bandwidth-rating-icon
    width: 32px
    height: 32px
    svg
      width: 100%
      height: 100%

  .not-active
    fill: #d8d8d8 !important

.bandwidth-rating-text
  font-size: 9px
  text-align: center
  white-space: nowrap
</style>
