<template>
  <v-dialog
    dark
    max-width="600"
    v-model="dialogWebrtcSources"
    persistent
    content-class="dialog-ask-init-webrtc-sources"
    key="key-dialog-ask-init-webrtc-sources"
    :transition="false"
  >
    <v-card
      v-if="selectiveScan && !testsStarted"
      data-testid="container-webrtc-selective-setup"
    >
      <v-card-title>WebRTC setup</v-card-title>
      <v-card-text>
        <v-flex sm12 xs12>
          <div class="pb-3" data-testid="description-webrtc-selective-setup">
            Webrtc sources are not initialised. Please select devices for scan
            resolution
          </div>
        </v-flex>

        <v-row>
          <v-col cols="12" sm="12">
            <div class="select-sources-container">
              <v-checkbox
                v-for="(source, key) in tempVideoSources"
                v-model="tempSources.selected"
                :label="source.label"
                :value="source.deviceId"
                :key="`chb-vsource-${key}`"
                :data-testid="`chbx-selective-source-${key}`"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="cancelSelectiveTest"
          color="red darken-1"
          text
          data-testid="btn-cancel-webrtc-selective-test"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="startSelectiveTest"
          color="success"
          text
          dark
          data-testid="btn-start-webrtc-selective-test"
        >
          Start
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showPropose && !testsStarted"
      data-testid="container-webrtc-setup"
    >
      <v-card-title>WebRTC setup</v-card-title>
      <v-card-text>
        <v-flex class="py-4" sm12 xs12 data-testid="description-webrtc-setup">
          <div class="pb-3">Webrtc sources are not initialised.</div>
          <div>
            Would you like to start local video/audio stream for source
            detection and scan? <br />
            You might see access request popup several times.
          </div>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="cancelTest"
          color="red darken-1"
          text
          data-testid="btn-cancel-webrtc-test"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="startTest"
          color="success"
          text
          dark
          data-testid="btn-start-webrtc-test"
        >
          Start
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="testsStarted" data-testid="container-running-webrtc-setup">
      <v-card-title>WebRTC sources setup</v-card-title>
      <v-card-text>
        <v-row
          justify="center"
          v-for="(test, key) in tests"
          :key="`row-test-${key}`"
          :data-testid="`row-running-test-${key}`"
        >
          <v-col cols="12" sm="12">
            <v-card>
              <v-card-title class="grey darken-4">
                <span
                  class="text-subtitle-1"
                  :data-testid="`title-running-test-${key}`"
                >
                  {{ key + 1 }}. {{ test.name }}
                </span>

                <v-spacer></v-spacer>

                <v-icon
                  color="amber accent-4"
                  v-if="test.status == statuses.pending"
                  :data-testid="`icon-pending-running-test-${key}`"
                  >mdi-dots-horizontal</v-icon
                >
                <v-icon
                  color="green darken-2"
                  v-if="test.status == statuses.finished"
                  :data-testid="`icon-finished-running-test-${key}`"
                  >mdi-check</v-icon
                >
                <v-progress-circular
                  :size="20"
                  indeterminate
                  color="green"
                  v-if="test.status == statuses.progress"
                  :data-testid="`icon-progress-running-test-${key}`"
                />
              </v-card-title>

              <v-list
                v-if="hasTasks(test)"
                :data-testid="`list-tasks-progress-running-test-${key}`"
              >
                <template v-for="(task, key2) in test.tasks">
                  <v-list-item
                    :key="'task-item-' + key2"
                    :data-testid="`container-task-test-${key}-${key2}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        :data-testid="`task-title-test-${key}-${key2}`"
                      >
                        {{ task.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        color="amber accent-4"
                        v-if="task.status == statuses.pending"
                        :data-testid="`icon-task-pending-test-${key}-${key2}`"
                      >
                        mdi-dots-horizontal
                      </v-icon>
                      <v-icon
                        color="green darken-2"
                        v-if="task.status == statuses.finished"
                        :data-testid="`icon-task-finished-test-${key}-${key2}`"
                      >
                        mdi-check
                      </v-icon>
                      <v-progress-circular
                        :size="20"
                        indeterminate
                        color="green"
                        v-if="task.status == statuses.progress"
                        :data-testid="`icon-task-progress-test-${key}-${key2}`"
                      />
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider
                    v-if="key2 + 2 <= test.tasks.length"
                    :key="'task-divider-' + key2"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-alert
          dense
          text
          type="success"
          v-if="isAllWebrtcSetupsInitialised"
          data-testid="notification-webrtc-setup-finished"
        >
          <strong>WebRTC setup was finished</strong>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isAllWebrtcSetupsInitialised"
          @click="cancelTest"
          color="red darken-1"
          text
          data-testid="btn-cancel-webrtc-test"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="isAllWebrtcSetupsInitialised"
          @click="finishTest"
          color="red darken-1"
          text
          data-testid="btn-ok-webrtc-test"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import { runResolutionScan } from "@/common/webrtc/resolution-scan.service"

const TEST_STATUS_PENDING = "test_status_pending"
const TEST_STATUS_INPROGRESS = "test_status_inprogress"
const TEST_STATUS_FINISHED = "test_status_finished"

const TEST_TYPE_SOURCES_SCAN = "test_type_sources_scan"
const TEST_TYPE_RESOLUTION_SCAN = "test_type_resolution_scan"

export default {
  name: "web-engine-dialog-sources",

  props: {
    showPropose: {
      type: Boolean,
      default: false,
    },
    selectiveScan: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tests: [],
      testsStarted: false,
      statuses: {
        pending: TEST_STATUS_PENDING,
        progress: TEST_STATUS_INPROGRESS,
        finished: TEST_STATUS_FINISHED,
      },
      dialog: true,
      tempSources: {
        all: [],
        selected: [],
      },
    }
  },

  watch: {
    dialogWebrtcSources: function (val) {
      let self = this
      if (val) {
        if (!this.showPropose && !this.selectiveScan) {
          this.startTest()
        }
        if (this.selectiveScan) {
          setTimeout(() => {
            self.initSelectiveDialog()
          }, 200)
        }
      }
      if (!val) this.tests = []
    },
  },

  methods: {
    initSelectiveDialog() {
      let self = this
      self.enumeratePcDevices().then((deviceInfos) => {
        self.tempSources.all = deviceInfos
      })
    },

    startSelectiveTest() {
      let self = this
      self.testsInitialFill()
      self.tests[0].status = TEST_STATUS_FINISHED
      self.testsStarted = true
      self.$store
        .dispatch("saveRawWebrtcSources", self.allTempSources)
        .then(() => {
          self.$store
            .dispatch("saveWebrtcSources", self.allTempSources)
            .then(() => {
              self.doInitialiseVideoResolutions()
            })
        })
    },

    startTest() {
      let self = this
      Promise.all([
        self.$store.dispatch("resetAllWebrtcSources"),
        self.$store.dispatch("resetScannedConstraints"),
      ]).then(() => {
        self.$emit("update:showPropose", false)
        self.testsInitialFill()
        self.testsStarted = true
        self.doInitialiseWebrtcSources().then(() => {
          self.tests[0].status = TEST_STATUS_FINISHED
          self.doInitialiseVideoResolutions()
        })
      })
    },

    testsInitialFill() {
      let self = this
      // add to tests list - check for WebRTC sources
      self.tests.push({
        name: "WebRTC sources scan",
        type: TEST_TYPE_SOURCES_SCAN,
        status: TEST_STATUS_PENDING,
        tasks: null,
      })

      // add to tests list - tasks for resolution scans for WebRTC video devices
      self.tests.push({
        name: "WebRTC video sources resolution scan",
        type: TEST_TYPE_RESOLUTION_SCAN,
        status: TEST_STATUS_PENDING,
        tasks: [],
      })
    },

    cancelTest() {
      this.stopAllScans()
      this.$store.dispatch("setDialogWebrtcSourcesStatus", false)
      this.$emit("cancel-scan")
    },

    cancelSelectiveTest() {
      this.stopAllScans()
      this.$store.dispatch("setDialogWebrtcSourcesStatus", false)
      this.$emit("update:selectiveScan", false)
      this.$emit("cancel-selective-scan")
    },

    doInitialiseWebrtcSources() {
      let self = this
      return new Promise((resolve, reject) => {
        if (this.isWebrtcSourcesInitialised) {
          self.tests[0].status = TEST_STATUS_FINISHED
          resolve()
        } else {
          self.tests[0].status = TEST_STATUS_INPROGRESS
          self.enumeratePcDevices().then((deviceInfos) => {
            self.gotDevices(deviceInfos).then(() => {
              resolve()
            })
          })
        }
      })
    },

    /**
     * Get PC devices
     * @returns {Promise<unknown>}
     */
    enumeratePcDevices() {
      let self = this
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .catch((err) => {
            alert(`Error on start stream for detection: ${err}`)
            // self.$store.dispatch('setDialogWebrtcSourcesStatus', false).then(() => {
            //   self.$emit('close-dialog')
            // })
          })
          .then(function (localStream) {
            navigator.mediaDevices
              .enumerateDevices()
              .then((deviceInfos) => {
                resolve(deviceInfos)
              })
              .catch((error) => {
                self.handleGetUserMediaError(error).then(() => {
                  resolve()
                })
              })
              .then(() => {
                if (localStream) {
                  localStream.getTracks().forEach((track) => track.stop())
                }
              })
          })
      })
    },

    doInitialiseVideoResolutions() {
      let self = this
      let resolutionTasks = []
      self.sourcesForScan.forEach((d) => {
        let resScanned = _.find(self.scannedConstraintsList, {
          deviceId: `${d.deviceId}`,
        })
        resolutionTasks.push({
          name: d.label ? d.label : d.deviceId,
          status: resScanned ? TEST_STATUS_FINISHED : TEST_STATUS_PENDING,
          device: d,
        })
      })
      if (typeof self.tests[1] === "undefined") {
        self.tests[1] = {}
      }
      self.tests[1].tasks = resolutionTasks
      self.scanResolutionProcess()
    },

    scanResolutionProcess() {
      let self = this
      if (typeof self.tests[1] === "undefined") {
        self.tests[1] = {}
      }
      let taskPending = _.find(self.tests[1].tasks, {
        status: TEST_STATUS_PENDING,
      })
      if (taskPending) {
        taskPending.status = TEST_STATUS_INPROGRESS
        runResolutionScan({
          device: taskPending.device,
          frameRate: 30,
        }).then((bestResolution) => {
          console.log("scanResolutionProcess--bestResolution", bestResolution)
          let scannedConstraints = bestResolution
            ? {
                deviceId: taskPending.device.deviceId,
                width: bestResolution.candidate.width,
                height: bestResolution.candidate.height,
                frameRate: bestResolution.frameRate,
              }
            : {
                deviceId: taskPending.device.deviceId,
                width: null,
                height: null,
                frameRate: 30,
              }
          let prms = [
            self.$store.dispatch("saveScannedConstraints", scannedConstraints),
            self.$store.dispatch(
              "saveUnchangedScannedConstraints",
              scannedConstraints
            ),
          ]

          Promise.all(prms).then(() => {
            taskPending.status = TEST_STATUS_FINISHED
            setTimeout(() => {
              self.scanResolutionProcess()
            }, 200)
          })
        })
      } else {
        self.tests[1].status = TEST_STATUS_FINISHED
      }
    },

    gotDevices(deviceInfos) {
      let self = this
      return new Promise((resolve, reject) => {
        this.tests[0].status = TEST_STATUS_FINISHED
        this.$store.dispatch("saveRawWebrtcSources", deviceInfos).then(() => {
          this.$store.dispatch("saveWebrtcSources", deviceInfos).then(() => {
            self.tempSources.all = deviceInfos
            self.tempSources.selected = self.tempVideoSources.map(
              (s) => s.deviceId
            )
            resolve()
          })
        })
      })
    },

    handleGetUserMediaError(error) {
      let self = this
      return new Promise((resolve, reject) => {
        self.tests[0].status = TEST_STATUS_FINISHED
        // self.$store.dispatch('setDialogWebrtcSourcesStatus', false).then(() => {
        //   resolve()
        // })
        console.log(
          "navigator.MediaDevices.getUserMedia error: ",
          error?.message,
          error?.name
        )
        resolve()
      })
    },

    hasTasks(test) {
      return test.tasks && test.tasks.length > 0
    },

    stopAllScans() {
      try {
        this.tests[0].status = TEST_STATUS_FINISHED
        this.tests[1].status = TEST_STATUS_FINISHED
      } catch (err) {
        console.log("Error on stopAllScans", err)
      }
    },

    finishTest() {
      this.$store.dispatch("setDialogWebrtcSourcesStatus", false).then(() => {
        if (this.selectiveScan) {
          this.$emit("update:selectiveScan", false)
          this.$emit("finished-selective-scan")
        } else {
          this.$emit("finished-scan")
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      showDialogInitSources: "showDialogInitSources",
      isWebrtcSourcesInitialised: "isWebrtcSourcesInitialised",
      isVideoDevicesResolutionsInited: "isVideoDevicesResolutionsInited",
      videoDevicesResolutionUnscanned: "videoDevicesResolutionUnscanned",
      rawVideoWebrtcSources: "rawVideoWebrtcSources",
      scannedConstraintsList: "scannedConstraintsList",
      isAllWebrtcSetupsInitialised: "isAllWebrtcSetupsInitialised",
      dialogWebrtcSources: "dialogWebrtcSources",
    }),

    showCurrentDialog: function () {
      return this.showDialogInitSources || (this.dialog && this.testsStarted)
    },

    tempVideoSources() {
      return this.tempSources.all.filter((s) => s.kind === "videoinput")
    },

    tempAudioSources() {
      return this.tempSources.all.filter((s) => s.kind === "audioinput")
    },

    /**
     * All temporary sources that includes:
     * - all audio sources
     * - selected video sources (in case of selectiveTest only those that was selected)
     * @returns {*[]|*}
     */
    allTempSources() {
      let videoSelectedSources = this.tempVideoSources.filter((s) =>
        this.tempSources.selected.includes(s.deviceId)
      )
      return [...this.tempAudioSources, ...videoSelectedSources]
    },

    sourcesForScan() {
      let videoSelectedSources = this.tempVideoSources.filter((s) =>
        this.tempSources.selected.includes(s.deviceId)
      )
      return videoSelectedSources
    },
  },
}
</script>

<style lang="scss">
.dialog-ask-init-webrtc-sources {
  overflow: hidden;
  .select-sources-container {
    max-height: 520px;
    overflow: auto;
  }
}
</style>
