<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    dark
    persistent
  >
    <v-card
      class="streamingWrapper"
      data-testid="container-webengine-streaming"
    >
      <v-form
        data-vv-scope="form-webengine-streaming"
        data-testid="form-webengine-streaming"
      >
        <!-- Alerts START -->
        <div class="streamingWrapperAlerts">
          <!--          <v-btn dark text color="green" class="ml-1" @click="$store.dispatch('resetAllWebrtcSources')">-->
          <!--            RESET SOURCES-->
          <!--          </v-btn>-->
          <FirefoxUseAlert
            :outlined="false"
            :text="false"
            v-if="!isFullScreen"
          />
          <v-alert
            v-show="!isPreviewRemoteStreamStarted && !errorsRemoteStream.show"
            border="left"
            color="blue"
            dismissible
            type="info"
            data-testid="notification-remote-stream-not-started"
            >Preview remote stream not started
          </v-alert>
          <v-alert
            v-show="!isLocalStreamStarted && !errorsLocalStream.show"
            border="left"
            color="blue"
            dismissible
            type="info"
            data-testid="notification-webcamera-stream-not-started"
            >Webcamera stream not started
          </v-alert>
          <v-alert
            v-show="!isWebRTCSupported"
            border="left"
            color="error"
            dismissible
            type="error"
            data-testid="notification-browser-webrtc-not-supported"
          >
            This browser not suporting webtc
          </v-alert>
          <v-alert
            type="error"
            v-show="errorsLocalStream.show"
            border="left"
            :color="errorsLocalStream.color"
            data-testid="notification-errors-local-stream"
          >
            <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:close="{ toggle }">
              <v-icon
                dark
                @click="
                  $store.dispatch('saveLocalStreamErrors', { show: false })
                "
                >mdi-close-circle
              </v-icon>
            </template>
            {{ errorsLocalStream.text }}
          </v-alert>
          <v-alert
            type="error"
            v-show="errorsRemoteStream.show"
            border="left"
            :color="errorsRemoteStream.color"
            data-testid="notification-errors-remote-stream"
          >
            <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:close="{ toggle }">
              <v-icon
                dark
                @click="
                  $store.dispatch('saveRemoteStreamErrors', { show: false })
                "
                >mdi-close-circle
              </v-icon>
            </template>
            {{ errorsRemoteStream.text }}
          </v-alert>
        </div>
        <!-- Alerts END -->

        <!-- Main receiving block START -->
        <v-card-text
          class="remoteView"
          v-on:dblclick="showHiddenElementsOnFullscreen()"
          v-on:click="clickRemoteViewBlock($event)"
        >
          <!-- mobile top toolbar Start -->
          <!--          <div class="mobileStreamingWrapperRemoteControls"-->
          <!--               v-if="$vuetify.breakpoint.smAndDown && showHiddenElementsComputed">-->

          <!--            <v-tooltip top :disabled="!showTooltipRemoteStream" open-delay="800">-->
          <!--              <template v-slot:activator="{ on, attrs }">-->
          <!--                <div-->
          <!--                  v-bind="attrs"-->
          <!--                  v-on="on"-->
          <!--                >-->
          <!--                  <v-select-->
          <!--                    outlined-->
          <!--                    v-model="activeWebEngine.remote.stream"-->
          <!--                    :items="webengineSendersSignal"-->
          <!--                    item-text="nickName"-->
          <!--                    return-object-->
          <!--                    :menu-props="{ maxHeight: '300', contentClass:'dropdown-content-width'}"-->
          <!--                    label="Asp stream"-->
          <!--                    hint="Available streams"-->
          <!--                    persistent-hint-->
          <!--                    v-on:input="startRemotePreviews"-->
          <!--                    class="toolbar-select"-->
          <!--                    v-show="!hasHashParam"-->
          <!--                    dense-->
          <!--                  ></v-select>-->
          <!--                </div>-->
          <!--              </template>-->
          <!--              <span>{{ activeWebEngine.remote.stream ? activeWebEngine.remote.stream.nickName : '' }}</span>-->
          <!--            </v-tooltip>-->

          <!--            <div class="mobileStreamingWrapperRemoteControls-nowrap">-->
          <!--              <BandwidthIndicator/>-->
          <!--              <div class="toolbar-item">-->
          <!--                <div class="toolbar-item-buttons">-->
          <!--                  <v-btn-->
          <!--                    class="toolbar-button-pre"-->
          <!--                    v-if="!remoteIsMuted"-->
          <!--                    @click="audioMute('remoteAudioVolume')"-->
          <!--                    color="transparent" dark>-->
          <!--                    <v-icon>mdi-volume-high</v-icon>-->
          <!--                  </v-btn>-->
          <!--                  <v-btn-->
          <!--                    class="toolbar-button-pre"-->
          <!--                    v-if="remoteIsMuted"-->
          <!--                    @click="audioUnmute('remoteAudioVolume')"-->
          <!--                    color="error" dark>-->
          <!--                    <v-icon>mdi-volume-off</v-icon>-->
          <!--                  </v-btn>-->
          <!--                  <v-menu class="streamingWrapperControls-popover" v-model="channelsMenu"-->
          <!--                          :close-on-content-click="false" :nudge-width="200" offset-y>-->
          <!--                    <template v-slot:activator="{ on, attrs }">-->
          <!--                      <v-btn class="toolbar-button" v-show="isPreviewRemoteStreamStarted" color="transparent" dark-->
          <!--                             v-bind="attrs" v-on="on">-->
          <!--                        <v-icon>mdi-menu-up</v-icon>-->
          <!--                      </v-btn>-->
          <!--                    </template>-->
          <!--                    <v-card>-->
          <!--                      <v-card-text>-->
          <!--                        <RemoteAudioChannels/>-->
          <!--                      </v-card-text>-->
          <!--                      <v-card-actions>-->
          <!--                        <v-spacer></v-spacer>-->
          <!--                        <v-btn text block @click="channelsMenu = false">-->
          <!--                          <v-icon>mdi-close</v-icon>-->
          <!--                        </v-btn>-->
          <!--                      </v-card-actions>-->
          <!--                    </v-card>-->
          <!--                  </v-menu>-->
          <!--                </div>-->
          <!--                <div class="toolbar-item-text">Mute</div>-->
          <!--              </div>-->
          <!--              <div class="toolbar-item">-->
          <!--                <div class="toolbar-item-buttons">-->
          <!--                  <v-btn-->
          <!--                    v-show="!isPreviewRemoteStreamStarted" dark color="error"-->
          <!--                    @click="startRemotePreviews()"-->
          <!--                    class="toolbar-button">-->
          <!--                    <v-icon dark>mdi-play</v-icon>-->
          <!--                  </v-btn>-->
          <!--                  <v-btn-->
          <!--                    v-show="isPreviewRemoteStreamStarted"-->
          <!--                    dark color="transparent"-->
          <!--                    class="toolbar-button"-->
          <!--                    @click="stopRemotePreviews()">-->
          <!--                    <v-icon dark>mdi-stop</v-icon>-->
          <!--                  </v-btn>-->
          <!--                </div>-->
          <!--                <div class="toolbar-item-text">Start/Stop</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!-- mobile top toolbar EBD -->

          <video
            v-show="isPreviewRemoteStreamStarted"
            id="received_video"
            :controls="false"
            :webkit-playsinline="true"
            :playsinline="true"
            data-testid="video-remote-stream"
          />
        </v-card-text>
        <!-- Main receiving block END -->

        <!-- audienceView block START -->
        <drr
          class="audienceView"
          :class="[showPreview ? 'visible' : 'not-visible']"
          :x="audienceView.x"
          :y="audienceView.y"
          :w="audienceView.width"
          :h="audienceView.height"
          :angle="audienceView.angle"
          :aspectRatio="audienceView.aspectRatio"
          :selectable="audienceView.selectable"
          :draggable="audienceView.draggable"
          :resizable="audienceView.resizable"
          :rotatable="audienceView.rotatable"
          @change="audienceViewChange"
          data-testid="container-draggable-local-stream"
        >
          <audio
            v-show="
              isLocalStreamStarted &&
              !mediaConstraints.video &&
              mediaConstraints.audio
            "
            id="local_audio"
            autoplay
            data-testid="audio-local-stream"
          />
          <video
            v-show="
              (isLocalStreamStarted && mediaConstraints.video) ||
              (!mediaConstraints.video && !mediaConstraints.audio)
            "
            ref="local_video"
            id="local_video"
            autoplay
            :controls="false"
            :webkit-playsinline="true"
            :playsinline="true"
            data-testid="video-local-stream"
          />
        </drr>
        <!-- audienceView block END -->

        <!-- main short toolbar START -->
        <div
          class="streamingWrapperControlsHidden controls-webengine"
          v-if="!showToolbar"
          data-testid="container-hidden-panel"
        >
          <v-spacer />
          <div class="toolbar-item" v-if="showHiddenElementsComputed">
            <div class="toolbar-item-buttons">
              <v-btn
                class="toolbar-button-pre"
                v-if="!localIsMuted"
                @click="audioMute('localAudioVolume')"
                color="transparent"
                dark
                data-testid="btn-mute-local-stream"
              >
                <v-icon>mdi-microphone</v-icon>
              </v-btn>
              <v-btn
                class="toolbar-button-pre"
                v-if="localIsMuted"
                @click="audioUnmute('localAudioVolume')"
                color="error"
                dark
                data-testid="btn-unmute-local-stream"
              >
                <v-icon>mdi-microphone-off</v-icon>
              </v-btn>
            </div>
            <div class="toolbar-item-text">Mute</div>
          </div>
          <div class="toolbar-item" v-if="showHiddenElementsComputed">
            <div class="toolbar-item-buttons">
              <v-btn
                dark
                color="transparent"
                class="toolbar-button"
                @click="showToolbar = true"
                data-testid="btn-show-panel"
              >
                <v-icon>mdi-menu-up</v-icon>
              </v-btn>
            </div>
            <div class="toolbar-item-text">Show Panel</div>
          </div>
          <div class="toolbar-item">
            <div class="toolbar-item-buttons">
              <v-btn
                v-show="!isFullScreen"
                color="transparent"
                dark
                class="toolbar-button"
                @click="openFullScreanStreaming()"
                :disabled="!isPreviewRemoteStreamStarted"
                data-testid="btn-enter-fullscreen"
              >
                <v-icon>mdi-fullscreen</v-icon>
              </v-btn>
              <v-btn
                v-show="isFullScreen"
                color="transparent"
                dark
                class="toolbar-button"
                @click="closeFullScreanStreaming()"
                :disabled="!isPreviewRemoteStreamStarted"
                data-testid="btn-exit-fullscreen"
              >
                <v-icon>mdi-fullscreen-exit</v-icon>
              </v-btn>
            </div>
            <div class="toolbar-item-text">Full screen</div>
          </div>
        </div>
        <!-- main short toolbar END -->

        <!-- main full toolbar START -->
        <div
          class="streamingWrapperControls"
          v-if="showToolbar"
          :class="showHiddenElementsComputed ? 'active' : 'not-active'"
          data-testid="container-panel"
        >
          <div
            class="streamingWrapperControls-name"
            v-if="showHiddenElementsComputed"
            data-testid="title-panel"
          >
            {{ activeWebEngine.name }}
          </div>

          <v-container class="pa-0" fluid>
            <v-layout
              row
              justify-space-between
              class="streaming-controls-container controls-webengine"
              data-testid="container-streaming-controls"
            >
              <v-flex xs4 sm3 data-testid="container-remote-stream-settings">
                <!-- Remote Group Controls -->
                <div class="streamingWrapperControls-remote">
                  <!--                  <v-tooltip top :disabled="!showTooltipRemoteStream" open-delay="800">-->
                  <!--                    <template v-slot:activator="{ on, attrs }">-->
                  <!--                      <div-->
                  <!--                        v-bind="attrs"-->
                  <!--                        v-on="on"-->
                  <!--                      >-->
                  <!--                        <v-select-->
                  <!--                          outlined-->
                  <!--                          v-model="activeWebEngine.remote.stream"-->
                  <!--                          :items="webengineSendersSignal"-->
                  <!--                          item-text="nickName"-->
                  <!--                          return-object-->
                  <!--                          :menu-props="{ maxHeight: '300', contentClass:'dropdown-content-width' }"-->
                  <!--                          label="Asp stream"-->
                  <!--                          hint="Available streams"-->
                  <!--                          persistent-hint-->
                  <!--                          v-on:input="startRemotePreviews"-->
                  <!--                          class="toolbar-select"-->
                  <!--                          v-show="!hasHashParam"-->
                  <!--                          dense-->
                  <!--                        ></v-select>-->
                  <!--                      </div>-->
                  <!--                    </template>-->
                  <!--                    <span>{{ activeWebEngine.remote.stream ? activeWebEngine.remote.stream.nickName : '' }}</span>-->
                  <!--                  </v-tooltip>-->

                  <BandwidthIndicator />

                  <div
                    class="toolbar-item"
                    v-if="!hasHashParam && showHiddenElementsComputed"
                  >
                    <div class="toolbar-item-buttons">
                      <v-btn
                        dark
                        :color="showStatistics ? 'transparent' : 'blue'"
                        class="toolbar-button-pre statistics-button"
                        @click="showStatistics = !showStatistics"
                        :disabled="!isPreviewRemoteStreamStarted"
                        data-testid="btn-statistics"
                      >
                        <v-icon dark>mdi-chart-line</v-icon>
                      </v-btn>
                    </div>
                    <div class="toolbar-item-text">Statistics</div>
                  </div>

                  <div class="toolbar-item">
                    <div class="toolbar-item-buttons">
                      <v-btn
                        class="toolbar-button-pre"
                        v-show="!remoteIsMuted"
                        @click="audioMute('remoteAudioVolume')"
                        color="transparent"
                        dark
                        data-testid="btn-mute-remote-stream"
                      >
                        <v-icon>mdi-volume-high</v-icon>
                      </v-btn>

                      <!-- Remote Channels -->
                      <v-menu v-model="channelsMenu" top offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="small-settings-button"
                            color="white"
                            absolute
                            fab
                            v-bind="attrs"
                            v-on="on"
                            v-show="isPreviewRemoteStreamStarted"
                            data-testid="btn-stop-remote-stream"
                          >
                            <v-icon>keyboard_arrow_up</v-icon>
                          </v-btn>
                        </template>
                        <v-card class="streamingWrapperControls-popover">
                          <v-card-text>
                            <RemoteAudioChannels />
                          </v-card-text>
                        </v-card>
                      </v-menu>

                      <v-btn
                        class="toolbar-button-pre"
                        v-show="remoteIsMuted"
                        @click="audioUnmute('remoteAudioVolume')"
                        color="error"
                        dark
                        data-testid="btn-unmute-remote-stream"
                      >
                        <v-icon>mdi-volume-off</v-icon>
                      </v-btn>
                    </div>

                    <div class="toolbar-item-text">Mute</div>
                  </div>

                  <!-- Stop/Start Remote stream -->
                  <div class="toolbar-item">
                    <div class="toolbar-item-buttons">
                      <v-btn
                        v-show="!isPreviewRemoteStreamStarted"
                        dark
                        color="error"
                        @click="startRemotePreviews()"
                        class="toolbar-button"
                        data-testid="btn-start-remote-stream"
                      >
                        <v-icon dark>mdi-play</v-icon>
                      </v-btn>
                      <v-btn
                        v-show="isPreviewRemoteStreamStarted"
                        dark
                        color="transparent"
                        class="toolbar-button"
                        @click="stopRemotePreviews()"
                        data-testid="btn-stop-remote-stream"
                      >
                        <v-icon dark>mdi-stop</v-icon>
                      </v-btn>
                    </div>
                    <div class="toolbar-item-text">Start/Stop</div>
                  </div>
                </div>
              </v-flex>
              <v-flex xs3 sm3 data-testid="container-local-stream-settings">
                <!-- Local Group Controls -->
                <v-layout justify-sm-space-around justify-space-between>
                  <div
                    class="streamingWrapperControls-local"
                    v-if="showHiddenElementsComputed"
                  >
                    <div class="toolbar-item">
                      <div class="toolbar-item-buttons">
                        <v-btn
                          class="toolbar-button-pre"
                          v-if="!localIsMuted"
                          @click="audioMute('localAudioVolume')"
                          color="transparent"
                          dark
                          data-testid="btn-mute-local-stream"
                        >
                          <v-icon>mdi-microphone</v-icon>
                        </v-btn>

                        <v-menu v-model="audioSourceMenu" top offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="small-settings-button"
                              color="white"
                              absolute
                              fab
                              v-bind="attrs"
                              v-on="on"
                              data-testid="btn-open-local-settings-menu"
                            >
                              <v-icon>keyboard_arrow_up</v-icon>
                            </v-btn>
                          </template>

                          <v-card
                            max-width="300"
                            tile
                            class="streamingWrapperControls-popover"
                          >
                            <v-card-text class="pa-1">
                              <v-list dense>
                                <v-subheader>Audio source</v-subheader>
                                <v-list-item-group
                                  v-model="selectedAudioSourceListPosition"
                                  color="primary"
                                >
                                  <v-list-item
                                    key="scan-audio-webrtc-devices"
                                    @click="
                                      selectVideoSource(
                                        WEBRTC_SOURCES_RESCAN_DEVICES
                                      )
                                    "
                                    class="red accent-2"
                                    data-testid="btn-rescan-local-sources-audio"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title
                                        >Re-Scan devices</v-list-item-title
                                      >
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-icon
                                        >mdi-monitor-cellphone-star</v-icon
                                      >
                                    </v-list-item-icon>
                                  </v-list-item>
                                  <v-list-item
                                    v-for="(item, i) in allAudioSources"
                                    :key="i"
                                    @click="selectAudioSource(item)"
                                    :data-testid="`btn-select-audio-source-${i}`"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-text="item.text"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>

                        <v-btn
                          class="toolbar-button-pre"
                          v-if="localIsMuted"
                          @click="audioUnmute('localAudioVolume')"
                          color="error"
                          dark
                          data-testid="btn-unmute-local-stream"
                        >
                          <v-icon>mdi-microphone-off</v-icon>
                        </v-btn>
                      </div>
                      <div
                        class="toolbar-item-text"
                        data-testid="title-local-stream-muted-status"
                      >
                        {{ localIsMuted ? "Unmute" : "Mute" }}
                      </div>
                    </div>

                    <div class="toolbar-item">
                      <div class="toolbar-item-buttons">
                        <v-btn
                          v-if="!isLocalStreamStarted"
                          dark
                          color="error"
                          @click="startLocalStream()"
                          class="toolbar-button-pre"
                          data-testid="btn-start-local-stream"
                        >
                          <v-icon dark>videocam_off</v-icon>
                        </v-btn>

                        <v-menu v-model="videoSourceMenu" top offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="small-settings-button"
                              color="white"
                              absolute
                              fab
                              v-bind="attrs"
                              v-on="on"
                              data-testid="btn-open-local-stream-video-settings"
                            >
                              <v-icon>keyboard_arrow_up</v-icon>
                            </v-btn>
                          </template>

                          <v-card
                            max-width="300"
                            tile
                            class="streamingWrapperControls-popover"
                            data-testid="container-local-video-source-settings"
                          >
                            <v-card-text class="pa-1">
                              <v-list dense>
                                <v-subheader>Video source</v-subheader>
                                <v-list-item-group
                                  v-model="selectedVideoSourceListPosition"
                                  color="primary"
                                >
                                  <v-list-item
                                    key="scan-video-webrtc-devices"
                                    @click="
                                      selectVideoSource(
                                        WEBRTC_SOURCES_RESCAN_DEVICES
                                      )
                                    "
                                    class="red accent-2"
                                    data-testid="btn-rescan-local-sources-video"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Re-Scan devices
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-icon
                                        >mdi-monitor-cellphone-star</v-icon
                                      >
                                    </v-list-item-icon>
                                  </v-list-item>
                                  <v-list-item
                                    v-for="(item, i) in allVideoSources"
                                    :key="i"
                                    @click="selectVideoSource(item)"
                                    :data-testid="`btn-select-video-source-${i}`"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.text
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>

                        <v-btn
                          v-if="isLocalStreamStarted"
                          dark
                          color="transparent"
                          class="toolbar-button-pre"
                          @click="stopLocalStream()"
                          data-testid="btn-stop-local-stream"
                        >
                          <v-icon dark>videocam</v-icon>
                        </v-btn>
                      </div>
                      <div class="toolbar-item-text">
                        {{
                          isLocalStreamStarted ? "Stop video" : "Start video"
                        }}
                      </div>
                    </div>
                  </div>
                </v-layout>
              </v-flex>
              <v-flex xs5 sm3 data-testid="container-common-settings">
                <!-- Settings Group -->
                <v-layout justify-end>
                  <div
                    class="streamingWrapperControls-actions"
                    :class="
                      showHiddenElementsComputed ? 'active' : 'not-active'
                    "
                  >
                    <div class="toolbar-item" v-if="showHiddenElementsComputed">
                      <div class="toolbar-item-buttons">
                        <v-btn
                          dark
                          color="transparent"
                          class="toolbar-button"
                          @click="showToolbar = false"
                          data-testid="btn-hide-panel"
                        >
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </div>
                      <div class="toolbar-item-text">Hide Panel</div>
                    </div>

                    <div
                      class="toolbar-item"
                      v-if="!hasHashParam && showHiddenElementsComputed"
                    >
                      <div class="toolbar-item-buttons">
                        <v-btn
                          dark
                          color="error"
                          class="toolbar-button-pre"
                          @click.native="closeDialog()"
                          data-testid="btn-stop-close"
                        >
                          <v-icon>stop</v-icon>
                        </v-btn>
                      </div>
                      <div class="toolbar-item-text">Stop & close</div>
                    </div>

                    <v-menu top offset-y v-if="showHiddenElementsComputed">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="toolbar-item">
                          <div class="toolbar-item-buttons">
                            <v-btn
                              class="toolbar-button"
                              color="transparent"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              data-testid="btn-open-settings-menu"
                            >
                              <v-icon>settings</v-icon>
                            </v-btn>
                          </div>
                          <div class="toolbar-item-text">Settings</div>
                        </div>
                      </template>

                      <v-card tile>
                        <v-card-text class="pa-1">
                          <v-list dense>
                            <v-list-item
                              :to="`/engines/webengine/edit/${editedIndex}`"
                              v-if="!hasHashParam && showHiddenElementsComputed"
                              data-testid="btn-edit-webengine"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-settings-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Edit Web Engine</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                              @click="rotatePreview()"
                              :disabled="!isLocalStreamStarted"
                              data-testid="btn-rotate-preview"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-format-rotate-90</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Rotate Preview</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                              @click="showPreview = !showPreview"
                              :disabled="!isLocalStreamStarted"
                              data-testid="btn-show-hide-preview-local-stream"
                            >
                              <v-list-item-icon>
                                <v-icon>{{
                                  showPreview ? "mdi-eye" : "mdi-eye-off"
                                }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >{{
                                    showPreview
                                      ? "Hide Preview"
                                      : "Show Preview"
                                  }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                              @click="settingsDialog = true"
                              data-testid="btn-open-video-quality-dialog"
                            >
                              <v-list-item-icon>
                                <v-icon>videocam</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Video Quality</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-menu>

                    <div class="toolbar-item">
                      <div class="toolbar-item-buttons">
                        <v-btn
                          v-if="!isFullScreen"
                          color="#363636"
                          dark
                          class="toolbar-button"
                          @click="openFullScreanStreaming()"
                          data-testid="btn-open-fullscreen"
                        >
                          <v-icon>mdi-fullscreen</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="isFullScreen"
                          color="transparent"
                          dark
                          class="toolbar-button"
                          @click="closeFullScreanStreaming()"
                          data-testid="btn-close-fullscreen"
                        >
                          <v-icon>mdi-fullscreen-exit</v-icon>
                        </v-btn>
                      </div>
                      <div class="toolbar-item-text">Full screen</div>
                    </div>
                  </div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <!-- main full toolbar END -->

        <!-- statistics block START -->
        <div
          class="streamingWrapperStatistics"
          :class="showStatistics ? 'active' : 'not-active'"
          v-if="isPreviewRemoteStreamStarted"
          data-testid="container-remote-stream-webrtc-statistics"
        >
          <webrtc-statistics
            v-if="
              receiver &&
              receiver.connection &&
              receiver.connection.id &&
              isPreviewRemoteStreamStarted
            "
            :connId="receiver.connection.id"
            theme="dark"
            class="pa-10"
            :saveStatsInStore="true"
          />
        </div>
        <!-- statistics block END -->

        <v-dialog v-model="settingsDialog" max-width="300px">
          <v-card data-testid="container-video-quality">
            <v-card-title>
              <span>Video Quality</span>
              <v-spacer></v-spacer>
              <v-btn fab dark small @click="settingsDialog = false">
                <v-icon dark> close </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-radio-group
                v-model="selectedWebcameraResolutionListPosition"
                column
                data-testid="rg-video-qualities-list"
              >
                <v-radio
                  v-for="(item, i) in listWebcameraResolutions"
                  :key="i"
                  :label="item.name"
                  :value="i"
                  @click="selectWebcameraResolution(item)"
                  :data-testid="`r-video-quality-${i}`"
                />
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>

    <web-engine-dialog-sources
      v-if="sourceScanInited && !showLoader"
      @close-dialog="closeDialog"
      @finished-scan="onEventScanDevices"
      @cancel-scan="onEventScanDevices"
      v-bind:showPropose.sync="showPropose"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import GeneralMixin from "@/mixins/general.js"
import WECommonMixin from "@/mixins/webengine/common"
import WebrtcStatistics from "@/components/webrtc/WebrtcStatistics"
import GeneralService from "@/common/general.service"
import RemoteAudioChannels from "@/components/engines/webengines/RemoteAudioChannels"
import AudenceViewMixin from "@/mixins/audience-view-mixin.js"
import BandwidthIndicator from "@/components/bandwidth/BandwidthIndicator"
import WebEngineDialogSources from "./WebEngineDialogSources"
import FirefoxUseAlert from "@/components/engines/FirefoxUseAlert"
import { WEBRTC_SOURCES_RESCAN_DEVICES } from "@/store/engines/webengines/constants"
import "@/plugins/vue-drag-resize-rotate"

export default {
  name: "web-engine-streaming",

  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    link_hash: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    WebrtcStatistics,
    RemoteAudioChannels,
    BandwidthIndicator,
    WebEngineDialogSources,
    FirefoxUseAlert,
  },

  mixins: [GeneralMixin, WECommonMixin, AudenceViewMixin],

  data() {
    return {
      prevAudioVolume: {
        localAudioVolume: 100,
        remoteAudioVolume: 100,
      },
      isFullScreen: false,
      collapsePreview: false,
      dialogSetUserName: false,
      timerShowToolbarFullscreen: null,
      expansionPanel: [1],
      dialog: true,
      sourceScanInited: false,
      streamsInited: false,

      showStatistics: false,
      showPreview: true,
      showToolbar: true,
      channelsMenu: false,
      audioSourceMenu: false,
      videoSourceMenu: false,
      showHiddenElements: false,
      settingsDialog: false,
      showPropose: false,
      WEBRTC_SOURCES_RESCAN_DEVICES: WEBRTC_SOURCES_RESCAN_DEVICES,
    }
  },

  watch: {
    roomMembers: function (newVal, oldVal) {
      //this.startStreamTemporaryFix();
    },

    isPreviewRemoteStreamStarted: function (newVal) {
      if (newVal) {
        this.sender.onBandwidthChange(this.activeWebEngine.remote.bandwidth)
        setTimeout(function () {
          let receivedVideo = document.getElementById("received_video")
          receivedVideo.setAttribute("controls", "false")
        }, 500)
      }
    },

    signalRoomMembersSenders: {
      handler: _.debounce(function (newVal, oldVal) {
        this.updateSendersList(newVal, oldVal)
      }, 1500),
      deep: true,
      immediate: true,
    },

    isFullScreen(val, oldVal) {
      let streamingControlsBlocks = Array.from(
        document.getElementsByClassName("controls-webengine")
      )
      if (!val && oldVal) {
        streamingControlsBlocks.forEach((elm) => {
          elm.classList.remove("d-none")
        })
      } else if (val && !oldVal) {
        streamingControlsBlocks.forEach((elm) => {
          elm.classList.add("d-none")
        })
      }
    },
  },

  mounted: function () {
    let self = this
    // @todo hack for dialog with WebRTC sources init to be over other dialogs
    setTimeout(() => {
      self.sourceScanInited = true
    }, 400)
    document.querySelector("html").style.overflow = "hidden"
    if (self.isWebRTCSupported) {
      let prmsReset = self.link_hash
        ? Promise.resolve()
        : self.$store.dispatch("webenginesResetData")
      prmsReset.then(() => {
        self.initDialogData().then(() => {
          Promise.all([
            self.$store.dispatch("initSelectedVideoWebrtcSource", {
              getDefaultValue: this.editedIndex === -1,
            }),
            self.$store.dispatch("initSelectedAudioWebrtcSource", {
              getDefaultValue: this.editedIndex === -1,
            }),
          ]).then(() => {
            self.createSignalConnections().then(() => {
              self.$store.dispatch("saveLoaderAction", false)
              if (self.hasHashParam) {
                self.showPropose = true
                setTimeout(() => {
                  self.startWebrtcScanDevices()
                }, 200)
              } else {
                self.runAllStreams().then(() => {
                  self.streamsInited = true
                })
              }
            })
          })
        })
      })
    }
  },

  beforeDestroy() {
    document.querySelector("html").style.overflow = null
    this.onDestroyPage()
  },

  methods: {
    clickRemoteViewBlock(evt) {
      if (this.isFullScreen) {
        let streamingControlsBlocks = Array.from(
          document.getElementsByClassName("controls-webengine")
        )
        console.log("streamingControlsBlocks", streamingControlsBlocks)
        let hasNoneClass = streamingControlsBlocks.find((elm) =>
          elm.classList.contains("d-none")
        )
        if (hasNoneClass) {
          streamingControlsBlocks.forEach((elm) => {
            elm.classList.remove("d-none")
          })
        } else {
          streamingControlsBlocks.forEach((elm) => {
            elm.classList.add("d-none")
          })
        }
      }
    },

    startRemotePreviews() {
      this.startPreviewAspxStream(this.activeWebEngine.remote.stream)
    },

    // onMouseMove() {
    //   if (this.isFullScreen) {
    //     clearTimeout(this.timerShowToolbarFullscreen);
    //     this.timerShowToolbarFullscreen = null;
    //     let webengineToolbar = document.getElementById('webengine-toolbar');
    //     webengineToolbar.classList.remove('hidden');
    //     let btnShowHidePreview = document.getElementById('id-show-hide-preview');
    //     btnShowHidePreview.classList.remove('hidden');
    //     this.timerShowToolbarFullscreen = setTimeout(() => {
    //       webengineToolbar.classList.add('hidden');
    //       btnShowHidePreview.classList.add('hidden');
    //     }, 2000)
    //   }
    // },
    showHiddenElementsOnFullscreen() {
      if (this.isFullScreen) {
        this.showHiddenElements = true
        setTimeout(() => {
          this.showHiddenElements = false
        }, 5000)
      }
    },

    openFullScreanStreaming() {
      let self = this
      return new Promise((resolve, reject) => {
        self.isFullScreen = true
        setTimeout(function () {
          self.openFullscreen(document.querySelector(".streamingWrapper"))
          if (document.addEventListener) {
            document.addEventListener(
              "fullscreenchange",
              self.exitHandler,
              false
            )
            document.addEventListener(
              "mozfullscreenchange",
              self.exitHandler,
              false
            )
            document.addEventListener(
              "MSFullscreenChange",
              self.exitHandler,
              false
            )
            document.addEventListener(
              "webkitfullscreenchange",
              self.exitHandler,
              false
            )
          }
          resolve()
        }, 500)
      })
    },

    exitHandler(e) {
      let self = this
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        self.isFullScreen = false
      }
    },

    closeFullScreanStreaming() {
      this.isFullScreen = false
      this.closeFullscreen(document.querySelector(".streamingWrapper"))
    },

    audioVolumeOut(type) {
      this["prevAudioVolume"][type] = this[type]
      this[type] = this[type] > 0 ? this[type] - 1 || 0 : 0
    },

    audioVolumeIn(type) {
      this["prevAudioVolume"][type] = this[type]
      this[type] = this[type] < 100 ? this[type] + 1 || 100 : 100
    },

    audioMute(type) {
      this[type] = 0
    },

    audioUnmute(type) {
      this[type] = this["prevAudioVolume"][type]
    },

    selectAudioSource(item) {
      let webrtcScansObj = WEBRTC_SOURCES_RESCAN_DEVICES
      if (item.deviceId === webrtcScansObj.deviceId) {
        this.startWebrtcScanDevices()
      } else {
        this.selectedAudioSource = item.deviceId
        this.runAllStreams()
      }
    },
    selectVideoSource(item) {
      let webrtcScansObj = WEBRTC_SOURCES_RESCAN_DEVICES
      if (item.deviceId === webrtcScansObj.deviceId) {
        this.startWebrtcScanDevices()
      } else {
        this.selectedVideoSource = item.deviceId
        this.runAllStreams()
      }
    },
    selectWebcameraResolution(item) {
      this.selectedResolution = item
      this.runAllStreams()
    },
    startWebrtcScanDevices() {
      this.$store.dispatch("setDialogWebrtcSourcesStatus", true)
    },
    onEventScanDevices() {
      if (!this.streamsInited) {
        Promise.all([
          this.$store.dispatch("initSelectedVideoWebrtcSource", {
            getDefaultValue: true,
          }),
          this.$store.dispatch("initSelectedAudioWebrtcSource", {
            getDefaultValue: true,
          }),
        ]).then(() => {
          this.runAllStreams()
        })
      }
    },
  },

  computed: {
    ...mapGetters({
      mediaConstraints: "getMediaConstraints",
      roomMembers: "getRoomMembersWsConn",
      modelWebengine: "getModelWebengine",
      isLocalStreamStarted: "getIsLocalStreamStarted",
      isPreviewRemoteStreamStarted: "getIsPreviewRemoteStreamStarted",
      localIsMuted: "getLocalStreamIsMuted",
      remoteIsMuted: "getRemoteStreamIsMuted",
      activeWebEngine: "getActiveWebEngine",
      getUserNameWebengineForm: "getUserNameWebengineForm",
      allVideoSources: "getAllVideoSources",
      allAudioSources: "getAllAudioSources",
      listWebcameraResolutions: "listWebcameraResolutions",
      resolutionString: "getResolutionString",
      activeSignalServerUrl: "getActiveSignalServerUrl",
      activeSignalRoom: "getActiveSignalRoom",
      activeLocalTurnData: "getActiveLocalTurnData",
      activeRemoteTurnData: "getActiveRemoteTurnData",
      signalRoomMembersSenders: "signalRoomMembersSenders",
      webengineSendersSignal: "webengineSendersSignal",
      showLoader: "getShowLoader",
      errorsLocalStream: "errorsLocalStream",
      errorsRemoteStream: "errorsRemoteStream",
    }),

    localAudioVolume: {
      get() {
        return this.$store.getters.getLocalStreamAudioVolume
      },
      set(newValue) {
        let self = this
        let volume = newValue / 100
        this.$store.dispatch("saveVolumeAudioLocalStream", volume).then(() => {
          self.sender.updateStreamVolume()
        })
      },
    },

    remoteAudioVolume: {
      get() {
        return this.$store.getters.getRemoteStreamAudioVolume
      },
      set(newValue) {
        let self = this
        let volume = newValue / 100
        this.$store.dispatch("saveVolumeAudioRemoteStream", volume).then(() => {
          self.receiver.updateStreamVolume()
        })
      },
    },

    userName: {
      get() {
        return this.$store.getters.getUserNameWebengineForm
      },
      set(newValue) {
        this.$store.dispatch("webenginesSetUserName", newValue)
      },
    },

    selectedVideoSource: {
      get() {
        return this.$store.getters.getSelectedVideoSourceWebengine({
          getDefaultValue: this.editedIndex === -1 || this.hasHashParam,
        })
      },
      set(newValue) {
        this.$store.dispatch("saveVideoInputMediaConstraints", {
          [GeneralService.getBrowserName()]: newValue,
        })
      },
    },

    selectedVideoSourceListPosition: function () {
      return _.findIndex(this.allVideoSources, {
        deviceId: this.selectedVideoSource,
      })
    },

    selectedAudioSource: {
      get() {
        return this.$store.getters.getSelectedAudioSourceWebengine({
          getDefaultValue: this.editedIndex === -1 || this.hasHashParam,
        })
      },
      set(newValue) {
        this.$store.dispatch("saveAudioInputMediaConstraints", {
          [GeneralService.getBrowserName()]: newValue,
        })
      },
    },

    selectedAudioSourceListPosition: function () {
      return _.findIndex(this.allAudioSources, {
        deviceId: this.selectedAudioSource,
      })
    },

    selectedResolution: {
      get() {
        return this.$store.getters.getSelectedResolutionWebengine
      },
      set(newValue) {
        this.$store.dispatch("saveVideoResolution", {
          width: newValue.width,
          height: newValue.height,
        })
      },
    },

    selectedWebcameraResolutionListPosition() {
      return this.selectedResolution
        ? _.findIndex(this.listWebcameraResolutions, {
            name: this.selectedResolution.name,
          })
        : null
    },

    // selectedAspxStream: {
    //    get() {
    //        return this.$store.getters.getSelectedAspxStreamTableData;
    //     },
    //     set(newValue) {
    //         let data = {selected: newValue, browser: GeneralService.getBrowserName()};
    //         this.$store.dispatch('saveVideoInputMediaConstraints', data);
    //     }
    // },

    showHiddenElementsComputed() {
      if (this.isFullScreen) {
        if (this.showHiddenElements) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    isWebRTCSupported() {
      return (
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        window.RTCPeerConnection
      )
    },
    showTooltipRemoteStream() {
      return (
        this.activeWebEngine.remote.stream &&
        this.activeWebEngine.remote.stream.nickName.length > 15
      )
    },
    allAudioSourcesAndScan() {
      return _.union(WEBRTC_SOURCES_RESCAN_DEVICES, this.allAudioSources)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/engine/webengine/webengine-rebuild.scss";
</style>
<style lang="scss" scoped>
video {
  pointer-events: none;
}

video::-webkit-media-controls {
  display: none !important;
}
</style>

<style lang="scss">
@import "@/assets/scss/vuetify/_select.scss";
</style>
