import { render, staticRenderFns } from "./RemoteAudioChannels.vue?vue&type=template&id=182b950b&scoped=true"
import script from "./RemoteAudioChannels.vue?vue&type=script&lang=js"
export * from "./RemoteAudioChannels.vue?vue&type=script&lang=js"
import style0 from "./RemoteAudioChannels.vue?vue&type=style&index=0&id=182b950b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182b950b",
  null
  
)

export default component.exports