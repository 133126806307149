import _ from "lodash"

export default {
  data() {
    return {
      audienceView: {
        x: 0,
        y: 0,
        width: 200,
        height: 200,
        angle: 0,
        aspectRatio: true,
        selectable: true,
        draggable: true,
        resizable: true,
        rotatable: true,
      },
    }
  },
  created() {
    window.addEventListener("resize", this.windowResized)
    this.getScreenDimensions()
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized)
  },
  methods: {
    rotatePreview() {
      if (this.audienceView.angle !== -270) {
        this.audienceView.angle = this.audienceView.angle - 90
      } else {
        this.audienceView.angle = 0
      }
    },
    audienceViewChange(rect) {
      this.audienceView.x = rect.x
      this.audienceView.y = rect.y
      this.audienceView.width = rect.w
      this.audienceView.height = rect.h
      this.audienceView.angle = rect.angle
      this.checkAudiencePosition()
    },
    checkAudiencePosition() {
      let buffer = 10
      if (this.$vuetify.breakpoint.smAndDown) buffer = 120

      let audience = _.cloneDeep(this.audienceView)
      let screen = {
        w: window.innerWidth,
        h: window.innerHeight,
      }
      let checkOuter = {
        isOuterRight: audience.x + audience.width / 2 > screen.w,
        isOuterBottom: audience.y + audience.height > screen.h,
        isOuterLeft: audience.x < audience.width / 2,
        isOuterTop: audience.y < audience.height / 2 + buffer,
      }
      if (checkOuter.isOuterRight) {
        this.audienceView.x = screen.w - audience.width / 2 - 10
      }
      if (checkOuter.isOuterLeft) {
        this.audienceView.x = audience.width / 2 + 10
      }
      if (checkOuter.isOuterTop) {
        this.audienceView.y = audience.height / 2 + buffer
      }
      if (checkOuter.isOuterBottom) {
        this.audienceView.y = screen.h - audience.height - buffer
      }
    },
    /* eslint-disable-next-line */
    windowResized(e) {
      this.getScreenDimensions()
      this.checkAudiencePosition()
    },
    getScreenDimensions() {
      let screen = {
        w: window.innerWidth,
        h: window.innerHeight,
      }
      this.setAudiencePosition(screen)
    },
    setAudiencePosition(screen) {
      let buffer = 10
      if (this.$vuetify.breakpoint.smAndDown) buffer = 120

      let audience = _.cloneDeep(this.audienceView)
      this.audienceView.x = screen.w - audience.width / 2
      this.audienceView.y = audience.height / 2 + buffer
    },
  },
  computed: {},
  watch: {
    mediaConstraints(val) {
      if (val) {
        let widthExact = val?.video?.width?.exact
        let heightExact = val?.video?.height?.exact
        let ratio = widthExact && heightExact ? widthExact / heightExact : 1.5
        this.audienceView.height = this.audienceView.width / ratio
      }
    },
  },
}
