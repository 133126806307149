<template>
  <div class="channels-list" data-testid="container-remote-audio-channels">
    <div
      v-if="hasRemoteAudioChannels && isPreviewRemoteStreamStarted"
      class="pa-4"
      data-testid="notification-empty-remote-audio-channels"
    >
      Can`t detect audio channels
    </div>
    <div
      v-for="ch in remoteAudioChannels"
      :key="ch.key"
      data-testid="container-list-remote-audio-channels"
    >
      <v-btn
        class="toolbar-button"
        v-if="!ch.muted"
        @click="toggleChannel(ch)"
        color="transparent"
        dark
        :data-testid="`btn-mute-audio-channel-${ch.key}`"
      >
        <v-icon>mdi-volume-high</v-icon>
        {{ `Channel ${ch.key + 1}` }}
      </v-btn>
      <v-btn
        class="toolbar-button"
        v-if="ch.muted"
        @click="toggleChannel(ch)"
        color="error"
        dark
        :data-testid="`btn-unmute-audio-channel-${ch.key}`"
      >
        <v-icon>mdi-volume-off</v-icon>
        {{ `Channel ${ch.key + 1}` }}
      </v-btn>
    </div>

    <!-- <div v-for="ch in remoteAudioChannels" :key="ch.key">
      <v-checkbox
        v-model="ch.muted"
        :label="`Mute channel ${ch.key + 1}`"
        @change="muteChannel(ch)"
      ></v-checkbox>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "RemoteAudioChannels",
  data() {
    return {
      audioChannels: [
        {
          key: 0,
          muted: true,
        },
        {
          key: 1,
          muted: false,
        },
      ],
    }
  },
  methods: {
    muteChannel(channel) {
      this.$store.dispatch("muteRemoteChannel", channel)
    },
    toggleChannel(channel) {
      channel.muted = !channel.muted
      this.$store.dispatch("muteRemoteChannel", channel)
    },
  },

  computed: {
    ...mapGetters({
      remoteAudioChannels: "getRemoteAudioChannels",
      isPreviewRemoteStreamStarted: "getIsPreviewRemoteStreamStarted",
    }),
    hasRemoteAudioChannels() {
      return !!_.isEmpty(this.remoteAudioChannels)
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar-button {
  // width: 70px !important
  min-width: 70px !important;
}
.channels-list > div:not(:last-child) {
  margin-bottom: 10px;
}
</style>
